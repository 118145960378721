@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Glowing effect */
  @keyframes glow {
    0% {
      box-shadow: 0 0 10px rgba(34, 197, 94, 0.5);
    }
    50% {
      box-shadow: 0 0 30px rgba(34, 197, 94, 1);
    }
    100% {
      box-shadow: 0 0 10px rgba(34, 197, 94, 0.5);
    }
  }
  
  .pulse {
    animation: pulse 2s infinite;
  }
  
  .glow {
    animation: glow 1.5s ease-in-out infinite;
  }
  